import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './bike_cal.css';

const fuelPrices = {
  regular: 1600,
  premium: 1800,
};

function Calculator() {
  const [mileage, setMileage] = useState(null);
  const [distance, setDistance] = useState(null);
  const [fuelType, setFuelType] = useState('regular');
  const [oilChangeInterval, setOilChangeInterval] = useState(null);
  const [oilChangeCost, setOilChangeCost] = useState(null);
  const [result, setResult] = useState(null);

  const pageTitle = '바이크 유지비 계산기 - Wooahah';

  useEffect(() => {
    // Kakao SDK 초기화
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init('47d30d9c0bcf103e0b2c4973e5eba289');
    }
  }, []);

  const shareOnKakao = () => {
    if (result) {
      window.Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: '바이크 유지비 계산기',
          description: `나는 바이크 1년 유지비용으로 \n${Math.round(result.yearly.fuelCost).toLocaleString()}원 을 써요.`,
          imageUrl: 'https://wooahah.com/images/bike_cal.jpg',
          link: {
            mobileWebUrl: 'https://wooahah.com/sub/bike_cal',
            webUrl: 'https://wooahah.com/sub/bike_cal',
          },
        },
        buttons: [
          {
            title: '나도 계산해보기',
            link: {
              mobileWebUrl: 'https://wooahah.com/sub/bike_cal',
              webUrl: 'https://wooahah.com/sub/bike_cal',
            },
          },
        ],
      });
    }
  };

  const calculateCost = () => {
    const weeklyDistance = distance * 10;
    const monthlyDistance = weeklyDistance * 4;
    const yearlyDistance = weeklyDistance * 52;

    const fuelUsedWeekly = mileage > 0 ? weeklyDistance / mileage : 0;
    const fuelUsedMonthly = mileage > 0 ? monthlyDistance / mileage : 0;
    const fuelUsedYearly = mileage > 0 ? yearlyDistance / mileage : 0;

    const fuelCostWeekly = fuelUsedWeekly * fuelPrices[fuelType];
    const fuelCostMonthly = fuelUsedMonthly * fuelPrices[fuelType];
    const fuelCostYearly = fuelUsedYearly * fuelPrices[fuelType];

    const oilChangesPerYear = oilChangeInterval > 0 ? yearlyDistance / oilChangeInterval : 0;
    const oilChangeCostYearly = oilChangesPerYear * oilChangeCost;

    setResult({
      weekly: {
        distance: weeklyDistance,
        fuel: fuelUsedWeekly,
        fuelCost: fuelCostWeekly,
        oilChangeCost: oilChangeCostYearly / 52,
      },
      monthly: {
        distance: monthlyDistance,
        fuel: fuelUsedMonthly,
        fuelCost: fuelCostMonthly,
        oilChangeCost: oilChangeCostYearly / 12,
      },
      yearly: {
        distance: yearlyDistance,
        fuel: fuelUsedYearly,
        fuelCost: fuelCostYearly,
        oilChangeCost: oilChangeCostYearly,
      },
    });
  };

  const resetCalculator = () => {
    setMileage(null);
    setDistance(null);
    setFuelType('regular');
    setOilChangeInterval(null);
    setOilChangeCost(null);
    setResult(null);
  };

  const copyToClipboard = () => {
    if (result) {
      const textToCopy = `나는 바이크 1년 유지비용으로 ${Math.round(result.yearly.fuelCost).toLocaleString()}원 을 써요.\n나도 계산해보기: https://wooahah.com/sub/bike_cal`;
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          alert('결과가 클립보드에 복사되었습니다! 카카오톡에 붙여넣으세요.');
        })
        .catch((err) => {
          console.error('클립보드 복사 실패', err);
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content="내 바이크 유지비는 얼마일까?" />
        <meta property="og:image" content="https://wooahah.com/images/bike_cal.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://wooahah.com/sub/bike_cal" />

        {/* 외부 배너 스크립트 */}
        <script src="/scripts/coupangBanner.js" type="text/javascript"></script>
      </Helmet>

      <div id="coupang-partner-banner" style={{ marginBottom: '20px', textAlign: 'center' }}></div>

      <form>
        <h2>바이크 유지비 계산기</h2>
        <div>
          <label>연비 (km/l):</label>
          <input
            type="number"
            value={mileage}
            onChange={(e) => setMileage(e.target.value)}
            placeholder="연비 입력"
          />
        </div>
        <div>
          <label>편도 출퇴근 거리 (km):</label>
          <input
            type="number"
            value={distance}
            onChange={(e) => setDistance(e.target.value)}
            placeholder="출퇴근 거리 입력"
          />
        </div>
        <div>
          <label>유종선택:</label>
          <select value={fuelType} onChange={(e) => setFuelType(e.target.value)}>
            <option value="regular">일반유</option>
            <option value="premium">고급유</option>
          </select>
        </div>
        <div>
          <label>엔진오일 교체 주기 (km):</label>
          <input
            type="number"
            value={oilChangeInterval}
            onChange={(e) => setOilChangeInterval(e.target.value)}
            placeholder="교체 주기 입력"
          />
        </div>
        <div>
          <label>엔진오일 교체 비용 (원):</label>
          <input
            type="number"
            value={oilChangeCost}
            onChange={(e) => setOilChangeCost(e.target.value)}
            placeholder="교체 비용 입력"
          />
        </div>
        <button type="button" onClick={calculateCost}>계산하기</button>
        <button type="button" onClick={resetCalculator}>다시 해보기</button>

        {result && (
          <div className="result-container">
            <h3>1주일에 {Math.round(result.weekly.fuelCost).toLocaleString()}원 써요</h3>
            <p>총 주행거리: {Math.round(result.weekly.distance).toLocaleString()} km</p>
            <p>총 소모연료: {Math.round(result.weekly.fuel).toLocaleString()} 리터</p>
            <p>유류비: {Math.round(result.weekly.fuelCost).toLocaleString()} 원</p>
            <p>엔진오일 교체비용: {Math.round(result.weekly.oilChangeCost).toLocaleString()} 원</p>

            <h3>1달에 {Math.round(result.monthly.fuelCost).toLocaleString()}원 써요</h3>
            <p>총 주행거리: {Math.round(result.monthly.distance).toLocaleString()} km</p>
            <p>총 소모연료: {Math.round(result.monthly.fuel).toLocaleString()} 리터</p>
            <p>유류비: {Math.round(result.monthly.fuelCost).toLocaleString()} 원</p>
            <p>엔진오일 교체비용: {Math.round(result.monthly.oilChangeCost).toLocaleString()} 원</p>

            <h3>1년에 {Math.round(result.yearly.fuelCost).toLocaleString()}원 써요</h3>
            <p>총 주행거리: {Math.round(result.yearly.distance).toLocaleString()} km</p>
            <p>총 소모연료: {Math.round(result.yearly.fuel).toLocaleString()} 리터</p>
            <p>유류비: {Math.round(result.yearly.fuelCost).toLocaleString()} 원</p>
            <p>엔진오일 교체비용: {Math.round(result.yearly.oilChangeCost).toLocaleString()} 원</p>

            <button type="button" onClick={shareOnKakao}>카카오톡 공유하기</button>
            <button type="button" onClick={copyToClipboard}>링크 복사하기</button>
          </div>
        )}

        <div id="coupang-carousel-banner" style={{ marginTop: '20px', textAlign: 'center' }}></div>
      </form>
    </>
  );
}

export default Calculator;
